// New nav
.new-nav {
  position: fixed; //remove
  top: 0px;
  z-index: 99; //remove

  width: 300px;
  height: 100%;
  background: #001847;
  overflow-x: hidden;
  overflow-y: auto;

  .header-contents {
    padding: 60px 0;

    .item-list {


      li {
        padding: 20px 0 20px 20px;

        &.manage-order {
          img {
            max-width: 19px;
            margin-right: 17px;
          }
        }

        .offering {
          // padding-bottom: 20px;
        }

        .info {
          // padding-bottom:20px;
        }

        .sub-menu {

          padding-left: 40px;

        }

        a {
          // color:#707070;
          color: #ffff;
          cursor: pointer;
        }
        .message-count{
          margin-right: 14px;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #5087E3;
          color: white;
          border-radius: 4px;
          padding: 0 8px;
          font-size: 10px;
        }

        &.active {
          background: #01153D;

        }
      }

      .desc {
        width: 100%;

      }

      .dropdown {
        padding: 20px;
      }
    }
  }
}

//Admin Dashboard scrollbar

.new-nav::-webkit-scrollbar{
  width: 5px;
}
.new-nav::-webkit-scrollbar-track {
  border-radius: 10px;
}
.new-nav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #a39cba;
}

.border-bottom {
  border-bottom: 1px solid #001F5B;
}
